html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fill-viewport {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.fill-viewport.landscape-left {
  left: env(safe-area-inset-left);
}
.fill-viewport.landscape-right {
  right: env(safe-area-inset-right);
}
#viewer {
  z-index: 1;
}
#preloadContainer {
  z-index: 2;
  opacity: 0;
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
}
